<template>
  <div class="quote-simple h-font-lg">
    <div class="quote-simple_content">
      <div class="vehicle h-group">
          <div class="h-cell h-cell--center">
              <div class="h-cell__title">
                  <van-icon :name="require('./image/vehicle.png')" size="18px" />车辆信息
              </div>
              <div class="h-cell__value">
                <span v-if="msgObj.vehicle" class="msg_warn"  @click="toPath('/miniprogram/quote/simple/vehicle')">{{msgObj.vehicle}}项信息待完善</span>
                <span v-else class="msg_success" @click="toPath('/miniprogram/quote/simple/vehicle')">信息已完善</span>
              </div>
            </div>
            <div class="h-cell__content">
              <van-row type="flex" gutter="15">
                <!-- <van-uploader :after-read="ocrVehicle" max-count="1"> -->
                  <van-col span="10" @click="plateOcrCli" class="ocr_bg ocr_bg--vehicle">
                    <span class="ocr-tips">扫描行驶证</span>
                    <template v-if="cardImg.vehicle">
                      <!-- <img
                          width="100%"
                          height="80%"
                          :src="cardImg.vehicle"
                        /> -->
                    </template>
                  </van-col>
                <!-- </van-uploader> -->
                <van-col span="14" class="ocr_msg" @click="toPath('/miniprogram/quote/simple/vehicle')">
                  <div class="h-font-lg h-font-bold-lg">{{(pageForm.vehicle && pageForm.vehicle.plateNo) || '新车未上牌'}}</div>
                  <div><span>车架号：</span>{{(pageForm.vehicle && pageForm.vehicle.frameNo) || 'xxx'}}</div>
                  <div><span>发动机号：</span>{{(pageForm.vehicle && pageForm.vehicle.engineNo) || 'xxx'}}</div>
                </van-col>
              </van-row>
            </div>
      </div>
      <div class="owner h-group">
          <div class="h-cell h-cell--center">
              <div class="h-cell__title">
                  <van-icon :name="require('./image/owner.png')" size="18px" />车主信息
              </div>
              <div class="h-cell__value">
                <span v-if="msgObj.owner" class="msg_warn"  @click="toPath('/miniprogram/quote/simple/owner')">{{msgObj.owner}}项信息待完善</span>
                <span v-else class="msg_success" @click="toPath('/miniprogram/quote/simple/owner')">信息已完善</span>
              </div>
            </div>
            <div class="h-cell__content">
              <van-row type="flex" gutter="15">
                <van-col span="10" @click="cardOcrCli" class="ocr_bg ocr_bg--owner">
                  <span class="ocr-tips">扫描身份证</span>
                </van-col>
                <van-col span="14" class="ocr_msg" @click="toPath('/miniprogram/quote/simple/owner')">
                  <div class="h-font-lg h-font-bold-lg owner-name">{{(pageForm.owner && pageForm.owner.name) || 'xxx'}}</div>
                  <div><span>电话号码：</span>{{(pageForm.owner && pageForm.owner.mobile) || (pageForm.owner && pageForm.owner.landlineNumber) || 'xxx'}}</div>
                  <div><span>证件号码：</span>{{(pageForm.owner && pageForm.owner.certificateNo) || 'xxx'}}</div>
                </van-col>
              </van-row>
            </div>
      </div>
      <div class="holder h-group">
        <div class="h-cell h-cell--center">
            <div class="h-cell__title">
                <van-icon :name="require('./image/holder.png')" size="18px" />投保人信息
            </div>
            <div class="h-cell__value">
              <span v-if="msgObj.holder||msgObj.insured" class="msg_warn">{{msgObj.holder+msgObj.insured}}项信息待完善</span>
              <span v-else class="msg_success">信息已完善</span>
            </div>
          </div>
          <div class="h-cell__content">
            <van-row gutter="10">
              <van-col span="12" class="holder_bg holder_bg--holder" @click="toPath('/miniprogram/quote/simple/holder')">
                <div>
                  <span class="h-font-sm" style="color:#969799">投保人</span><br/>
                  <span class="h-font-xl h-font-bold h-font-people">{{(pageForm.holder && pageForm.holder.name) || 'xxx'}}</span>
                </div>
                <span @click.stop="holderClick" class="check" v-if="pageForm.holder">
                  <van-checkbox v-model="pageForm.holder.sameOwnerFlag" shape="square" class="h-font-sm" style="background: none; padding: 0;" checkedColor="#57ccc2">同车主</van-checkbox>
                </span>
              </van-col>
              <van-col span="12" class="holder_bg holder_bg--insured" @click="toPath('/miniprogram/quote/simple/insured')">
                <div>
                  <span class="h-font-sm" style="color:#969799">被保人</span><br/>
                  <span class="h-font-xl h-font-bold h-font-people">{{(pageForm.insured && pageForm.insured.name) || 'xxx'}}</span>
                </div>
                <span @click.stop="holderClick" class="check" style="display: inline-block; width: 44%;" v-if="pageForm.insured">
                  <van-checkbox v-model="pageForm.insured.sameOwnerFlag" shape="square" class="h-font-sm" style="background: none; padding: 0;" checkedColor="#57ccc2">同车主</van-checkbox>
                </span>
                <span @click.stop="holderClick" class="check" style="display: inline-block; width: 50%;" v-if="pageForm.insured">
                  <van-checkbox v-model="pageForm.insured.sameHolderFlag" shape="square" class="h-font-sm" style="background: none; padding: 0;" checkedColor="#57ccc2">同投保人</van-checkbox>
                </span>
              </van-col>
            </van-row>
          </div>
      </div>
      <div class="risk h-group">
            <div class="h-cell h-cell--center">
              <div class="h-cell__title">
                  <van-icon :name="require('./image/risk.png')" size="18px" />投保方案
              </div>
              <div class="h-cell__value">
                <span v-if="riskErrorTotal" class="msg_warn"  @click="toPath('/miniprogram/quote/simple/risk', 'add')">{{riskErrorTotal}}项信息待完善</span>
                <span v-else class="msg_success" @click="toPath('/miniprogram/quote/simple/risk', 'add')">信息已完善</span>
              </div>
            </div>
            <div class="h-cell__content" >
              <van-row gutter="15">
                <!-- <van-col span="10" class="ocr_bg ocr_bg--risk">
                  <van-icon :name="require('./image/disabled.png')" size="18px" />
                </van-col> -->
                <van-col v-if="pageForm.commercial" span="22" class="risk_select">
                  <van-radio-group v-model="pageForm.commercial.insuredPlan" icon-size="16px" @change="insuredPlanChange">
                    <div class="risk_select_item">
                      <van-radio name="1" shape="square" checked-color="#ed6a0c" />
                      <div>常用方案</div>
                    </div>
                    <div class="risk_select_item">
                      <van-radio name="2" shape="square" checked-color="#ed6a0c" />
                      <div>全险</div>
                    </div>
                    <div class="risk_select_item">
                      <van-radio name="3" shape="square" checked-color="#ed6a0c" />
                      <div>自定义</div>
                    </div>
                    <div class="risk_select_item">
                      <van-radio name="4" shape="square" checked-color="#ed6a0c" />
                      <div>同上次报价单</div>
                    </div>
                  </van-radio-group>
                </van-col>
              </van-row>
              <!-- <div class="risk_file">拍照/上传上年保单 ></div> -->
            </div>
      </div>
      <VehicleSelect ref="vehicleSelectRef" @selectVehicle="selectVehicle"/>
      <CheckCode ref="checkCodeRef" @checkCode="checkCode"/>
      <CardOcr ref="cardOcr" :type="'owner'"/>
      <PlateOcr ref="plateOcr" />
    </div>
    <div class="quote-simple_bnt">
      <div class="quote-simple_bnt--fixed" >
        <van-button :loading="pageData.submitLoading" loading-text="报价中" type="primary" block round @click="submit">查询报价</van-button>
      </div>
    </div>
</div>
</template>
<script>
import layout from "../layout/index";
import mixinBase from '../common/mixin/base';
import mixinRisk from '../common/mixin/risk';
import mixinCommon from '../common/mixin/common';
import AsyncValidator from 'async-validator';
import VehicleSelect from '../components/VehicleSelect';
import CheckCode from '../components/CheckCode';
import { mapState } from "vuex";
import { Notify,Dialog } from "vant";
import { mergeWith, cloneDeep } from "lodash";
import CardOcr from '@/components/cardOcr/index'
import PlateOcr from '@/components/plateOcr/index'
import riskWatch from '@/utils/riskWatch'
export default {
  name: "QuoteSimple",
  components: {VehicleSelect, CheckCode,CardOcr, PlateOcr},
  mixins: [mixinBase,mixinRisk,mixinCommon,riskWatch],
  provide () {
		return {
			pageContext: this.pageContext
		}
	},
  provide () {
    return {
        pageContext: this.pageContext
    }
  },
  data() {
    return {
      pageContext: this,
      pageData: {
        submitLoading:false
      },
      pageRiskForm: {},
      pageRiskLayout:{},
      msgObj:{
        vehicle:0,
        owner:0,
        holder:0,
        insured:0,
        risk:0,
        basis:0,
        compulsory:0,
        commercial:0
      },
      config: {
        scriptConfig: null,
        formLayout: {},
      },
      commercialFlag:null
    };
  },
  watch:{
    pageLayout:{
      handler(newVal,oldVal){
        this.initValidate()
      },
      deep:true
    },
    pageForm:{
      handler(newVal,oldVal){
        this.initValidate()
      },
      deep:true
    }
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("car", ["quote","quoteRes","cardImg"]),
    riskErrorTotal(){
      if(!(this.pageForm.compulsory||this.pageForm.commercial)){
        return null
      }
      let totle=this.msgObj.basis || 0;
      if(!(this.pageForm.compulsory.checked || this.pageForm.commercial.checked)){
        totle++
        return totle
      }
      if(this.pageForm.compulsory.checked){
        totle+=(this.msgObj.compulsory||0)
      }
      if(this.pageForm.commercial.checked){
        totle+=(this.msgObj.commercial+this.msgObj.risk)
      }
      return totle
    }
  },
  async created() {
    await this.initData();
    this.initLayout();
    this.initValidate();
    if (this.pageForm.basis.insuredOrgCode === 'YGBX') {
        this.initRiskVal(this.pageRiskForm)
    }
  },
  methods: {
    initValidate(){
      this.validate('vehicle')
      this.validate('owner')
      this.validate('holder')
      this.validate('insured')
      this.validate('basis')
      this.validate('compulsory')
      this.validate('commercial')
      this.validateRisk()
    },
    validate(code){
      const model=this.pageForm[code]
      const layout = this.pageLayout[code]
      if(!(model||layout)){
        return null
      }
      const descriptor = {}
      for(const codeKey in layout){
        const itemObj=layout[codeKey]
        if(!itemObj.show){
          continue;
        }
        descriptor[codeKey]=(itemObj.config.rules&&itemObj.config.rules.filter((item=>!item.disabled))) || []
      }
      const validator = new AsyncValidator(descriptor);
      validator.validate(model, { firstFields: true }, (errors) => {
        if(errors){
          // const message=errors.map(item=>item.message).join(',')
          this.msgObj[code]=errors.length
        }else{
          this.msgObj[code]=null
        }
      });
    },
    validateRisk(){
      this.msgObj.risk=0
      for(const riskCode in this.pageRiskForm){
        const riskForm=this.pageRiskForm[riskCode]
        if(!riskForm.riskCheck){
          continue;
        }
        const model=riskForm
        const descriptor = {}
        const layoutRisk=this.pageRiskLayout[riskCode]
        for(const itemCode in layoutRisk){
          const itemObj=layoutRisk[itemCode]
          descriptor[itemCode]=(itemObj.config.rules&&itemObj.config.rules.filter((item=>!item.disabled))) || []
        }
        const validator = new AsyncValidator(descriptor);
        validator.validate(model, { firstFields: true }, (errors) => {
          if(errors){
            this.msgObj.risk+=errors.length
          }
        });
      }
    },
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    initLayout(){
      this.initLayoutBase();
      this.initLayoutRisk();
      //赋值
      this.$set(this.pageForm.commercial,'insuredPlan',null)
      this.$set(this.pageForm.holder,'sameOwnerFlag',null)
      this.$set(this.pageForm.insured,'sameHolderFlag',null)
      this.$set(this.pageForm.insured,'sameOwnerFlag',null)
      this.$set(this.pageForm.vehicle,'plateFlag',null)
      // 车辆使用性质处理
      let quote = cloneDeep(this.quote)
      quote.vehicle.vehicleUsageDetail = quote.owner.custType === '1' ? '03' : '01'
      mergeWith(this.pageForm,this.quote)
      this.pageForm.commercial.riskList&&this.pageForm.commercial.riskList.forEach(riskItem=>{
        if(!this.pageRiskForm[riskItem.riskCode]){
          return false
        }
        Object.assign(this.pageRiskForm[riskItem.riskCode],riskItem)
      })
      this.pageForm.owner.certificateType = this.pageForm.owner.custType === '1' ? '6' : this.pageForm.owner.certificateType
      this.$store.dispatch("car/setQuote", this.pageForm);//保存缓存
    },
    initLayoutBase() {
      const layoutData = layout("BASE");
      if (layoutData == null) {
        return null;
      }
      const layoutAddData = layoutData.addConfig(this,this.pageForm,this.pageLayout);
      this.initPageData(layoutAddData)
      Object.assign(this.config.formLayout,layoutAddData)
      return layoutData;
    },
    initLayoutRisk() {
      const layoutData = layout("RISK");
      if (layoutData == null) {
        return null;
      }
      const layoutAddData = layoutData.addConfig(this,{newEnergyFlag:this.quote.vehicle&&this.quote.vehicle.newEnergyFlag});
      this.initPageData(layoutAddData)
      this.initPageRiskData(layoutAddData.commercial.riskItems)
      Object.assign(this.config.formLayout,layoutAddData)
      this.config.riskLayout = layoutAddData.commercial.riskItems;
      this.$set(this.pageForm.commercial, 'checked', null)
      this.$set(this.pageForm.compulsory, 'checked', null)
      return layoutData;
    },
    toPath(path, type){
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      if (type) {

      }
      this.$router.push({ path: path});
    },
    submit(){
      if(this.msgObj.vehicle||this.msgObj.owner||this.msgObj.holder||this.msgObj.insured||this.riskErrorTotal){
        Notify({ type: "warning", message: "请完善信息" });
        return false
      }
      this.vehicleQuoteIn()
    },
    holderClick () {
    },
    plateOcrCli () {
        this.$refs.plateOcr.cardShow = true
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.quote-simple{
  background-color: $background-color;
  height: 100vh;
  &_content{
    padding:5px ;
  }
  .h-group{
    margin-bottom: 10px;
    padding: 8px;
    box-shadow:$shadow-1-down;
    border-radius: $border-radius-lg;
  }
  &_bnt{
    height: 64px;
    &--fixed{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 99;
      background-color: $white;
      padding: 10px;
      border-top: $background-color solid $border-radius-sm;
    }
  }
}
.h-cell__title{
  font-size: $font-size-lg;
  font-weight: $font-weight-bold-lg;
  .van-icon{
    vertical-align: middle;
    padding-right: 5px;
  }
}
.h-cell__content{
  padding: 8px;
  .ocr_bg{
    border: $border-width-base dashed $border-color;
    border-radius: 10px;
    height: 74px;
    width: 100px;
    &--vehicle{
      background:$background-color url('./image/vehicle_bg.png') center no-repeat;
      background-size: 80%;
    }
    &--owner{
      background:$background-color url('./image/owner_bg.png') center no-repeat;
      background-size: 80%;
    }
    &--risk{
      height: 110px;
      width: 110px;
      background:$background-color url('./image/risk_bg.png') center no-repeat;
      background-size: 70%;
      opacity: 0.5;
      position: relative;
      .van-icon {
        position: absolute;
        top: 42%;
        left: 41%;
      }
    }
  }
  .ocr_msg{
    font-size: $font-size-sm;
    div{
      padding: 2px 0;
      white-space:nowrap;
    }
    span{
      color:$gray-6
    }
  }
  .holder_bg{
    height: 74px;
    border-radius: 10px;
    background-clip:content-box;
    &--holder{
      background-image: linear-gradient(180deg, #EEFBFA 0%, #FFFFFF 100%);
      div{
        padding: 10px;
        background: url('./image/holder_bg.png') no-repeat 90% center;
        background-size: auto 70%;
      }
    }
    &--insured{
      background-image: linear-gradient(180deg, #FEF2F8 0%, #FFFFFF 100%);
      div{
        padding: 10px;
        background: url('./image/insured_bg.png') no-repeat 90% center;
        background-size: auto 70%;
      }
    }
  }
}
.risk{
  .risk_select{
    &_item{
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 4px 5px;
      color:$gray-6;
      div[aria-checked=true] + div{
        font-weight: $font-weight-bold-lg;
        color: $text-color;
      }
    }
  }
  .risk_file{
    color:$primary-color;
    padding: 5px;
    font-size: $font-size-sm;
  }
}

.msg_warn{
  padding: 4px;
  font-size: $font-size-sm;
  color:$orange-dark ;
  background-color: $orange-light;
  border-radius: $border-radius-sm;
  border:$orange solid $border-width-base;
}
.msg_success{
  padding: 4px;
  font-size: $font-size-sm;
  color:$primary-color ;
  border-radius: $border-radius-sm;
  border:$primary-color solid $border-width-base;
}
.h-font-people {
  display: inline-block;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.owner-name {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.ocr-tips{
  font-size: 9px;
  position: relative;
  left: 31%;
  top: -6%;
  color: red;
}
.check ::v-deep {
  position: relative;
  left: 10px;
  .van-icon {
    font-size: 10px;
  }
  .van-checkbox__label {
    font-size: 11px;
    margin-bottom: 7px;
  }
}
.holder ::v-deep {

}

</style>
